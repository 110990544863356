<script>
import Mixin from '../mixin'
import menuToggled from '../../../mixins/menuToggled'
import Acessos from './monitor/Acessos'
import HTheme from './monitor/highchartsTheme'
import Aprovacao from './monitor/Aprovacao';
import Cadastros from './monitor/Cadastros';
import OrigemAcesso from './monitor/OrigemAcesso';
export default {
  name: 'MonitoramentoArrematantes',
  mixins: [Mixin, menuToggled],
  components: {
    OrigemAcesso,
    Cadastros,
    Aprovacao,
    Acessos
    // Layout,
  },
  data () {
    return {}
  },
  computed: {},
  methods: {},
  meta: {
    title: 'Monitor Leiloeiro',
    name: 'Monitor Leiloeiro'
  }
}
</script>

<template>
  <div class="monitor-arrematante flex col-grow no-wrap">
    <div>
      <acessos />
    </div>
    <div>
      <aprovacao />
    </div>
    <div>
      <cadastros />
    </div>
    <div>
      <origem-acesso />
    </div>
  </div>
</template>

<style lang="stylus">
  .monitor-arrematante{
    background-color: #12161F;
    display flex
    flex-wrap wrap
    flex-direction row
    > div{
      flex 1
      width 48%
      min-width 48%
      max-width 48%
      background-color #2E3442
      padding 20px
      margin 1%
    }

    .chart-container{
      width 100%
    }
  }
</style>

